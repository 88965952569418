@use "../../styles/partials/colors" as *;
@use "../../styles/partials/media" as *;
@use "../../components/Form/Form.scss";
@use "../../components/Hero/Hero.scss";

.upload-section {
  padding: 0 1rem;

  @include tablet {
    padding: 0 2rem;
  }
  @include desktop {
    padding: 0 6rem;
  }
}

.thumbnail {
  @extend .form__label;
  margin-bottom: 0.5rem;

  &__image {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 1.2rem;
    @include tablet {
      width: 50%;
    }
    @include desktop {
      width: 37%;
    }
  }
}

.form__upload {
  @include desktop {
    position: absolute;
    top: 13rem;
    left: 36rem;
    width: 35%;
  }
}

.form__input--title {
  @extend .form__input;
  height: 1.25rem;
  @include tablet {
    height: 2.25rem;
  }
  @include desktop {
    height: 1.25rem;
  }
}

.form__input--description {
  @extend .form__input;

  @include tablet {
    height: 6rem;
  }
  @include desktop {
    height: 4rem;
  }
}

.form__button--publish {
  @extend .form__button;
  @include tablet {
    position: absolute;
    right: 2rem;
  }
  @include desktop {
    right: 6rem;
    width: 15%;
  }
}

.form__button--cancel {
  @extend .form__button;
  background-color: $white;
  color: $primary-blue;
  @include tablet {
    position: absolute;
    right: 15rem;
  }
  @include desktop {
    right: 20rem;
    width: 15%;
  }
}

.divider--upload {
  display: none;
  @extend .divider;
  @include tablet {
    display: block;
    margin: 1rem 0;
  }
}
