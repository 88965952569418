@use '../../styles/partials/media' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/colors' as *;

.sidevideo {
    display: block;

    &__image {
        max-width: 8rem;
        max-height: 5rem;
        background-color: $mercury;
        border-radius: 4px;
        margin-right: 1rem;
        flex: 1;
        object-fit: cover;

        @include desktop {
            max-width: 8rem;
            max-height: 5rem;
            flex: 50%;
        }
    }

    &__heading {
        @include subheading;
        color: $silver;
        margin: 2rem 1rem;
    }

    &__card {
        display: flex;
        margin: 1rem;
        text-decoration: none;
    }

    &__details {
        flex: 2;
        @include subheading;

        @include desktop {
            flex: 50%;
            font-size: 0.9rem;
        }

        &--title {
            margin: 0;

            @include tablet {
                width: 45%;
            }

            @include desktop {
                width: 100%;
            }
        }

        &--channel {
            @include body-text;
            margin-bottom: 0;
            margin-top: 0.5rem;

            @include desktop {
                font-size: 0.9rem;
            }
        }
    }
}