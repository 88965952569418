@use '../../styles/partials/media' as *;
@use '../../styles/partials/colors' as *;

.video-details {
    @include desktop {
        display: flex;
    }

    &--main {
        flex: 2;
    }

    &--side {
        @include desktop{
            margin-right: 3rem;
        }
        flex: 1;
        border-left: 1px solid $mercury;
        
    }
}