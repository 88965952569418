@use "../../styles/partials/colors" as *;
@use "../../styles/partials/media" as *;
@use "../../styles/partials/fonts" as *;
@use "../Header/Header.scss" as *;

.form {
  display: flex;

  @include desktop {
    padding-right: 1rem;
  }

  &__label {
    @include subheading;
    color: $silver;
    width: 100%;

    @include desktop {
      font-size: 1rem;
    }
  }

  &__input {
    @include body-text;
    width: 100%;
    height: 5.25rem;
    padding: 1rem 0;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    border: 1px solid $mercury;
    border-radius: 4px;
    text-indent: 0.75rem;
    background-color: $alabaster;

    @include tablet {
      height: 2rem;
      padding: 0.4rem 0;
    }
  }

  &__fields {
    width: 100%;

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__block {
    @include tablet {
      flex: 3;
      margin-right: 2rem;
    }
    @include desktop {
      flex: 3.5;
      margin-right: 1rem;
    }
  }

  &__button {
    @extend .header__button;
    margin: 0 0 1rem 0;
    width: 100%;
    flex: 1;

    @include tablet {
      width: 25%;
      margin: 0.25rem 0 0 0;
    }

    @include desktop {
      flex: 1.5;
    }
  }
}

.form-section {
  margin: 0 1rem;

  @include tablet {
    margin: 0 2rem;
  }

  @include desktop {
    padding: 0 0 0 6rem;
    margin: 0;
  }

  &__heading {
    @include subheading;
  }
}
