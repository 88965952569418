// === MEDIA QUERIES ===

@mixin tablet {
    @media (min-width:768px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width:1024px) {
        @content;
    }
}