@use './media' as *;
@use './colors' as *;

@font-face {
    font-family: "AvenirNextBold";
    src: url(../../assets/fonts/AvenirNextLTPro-Bold.otf) format("opentype"),
        url(../../assets/fonts/Alternate/AvenirNextLTProBold.ttf) format("truetype");
}

@font-face {
    font-family: "AvenirNextDemi";
    src: url(../../assets/fonts/AvenirNextLTPro-Demi.otf) format("opentype"),
        url(../../assets/fonts/Alternate/AvenirNextLTProDemi.ttf) format("truetype");
}

@font-face {
    font-family: "AvenirNext";
    src: url(../../assets/fonts/AvenirNextLTPro-Regular.otf) format("opentype"),
        url(../../assets/fonts/Alternate/AvenirNextLTPro.ttf) format("truetype");
}

// ===TYPOGRAPHY===

@mixin page-heading {
    font-family: "AvenirNextBold";
    font-size: 2.1rem;
    color: $primary-black;

    @include desktop {
        font-size: 1.9rem;
    }
}

@mixin section-heading {
    font-family: "AvenirNextDemi";
    font-size: 2rem;
    line-height: 3rem;
    color: $primary-black;

    @include desktop {
        font-size: 1.8rem;
    }
}

@mixin subheading {
    font-family: "AvenirNextDemi";
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: $primary-black;

    @include desktop {
        font-size: 1rem;
    }
}

@mixin body-text {
    font-family: "AvenirNext";
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: $primary-black;

    @include desktop {
        font-size: 1rem;
    }
}