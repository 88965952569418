@use '../../styles/partials/media' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/colors' as *;

.hero {
    padding: 0 1rem;

    @include tablet {
        padding: 0 2rem;
    }

    @include desktop {
        padding: 0 0 0 6rem;
    }

    &__banner {
        background-color: $secondary-black;
        margin-top: 1rem;

        @include tablet {
            margin-top: 0;
        }
    }

    &__video {
        width: 100%;

        @include tablet {
            margin: 0;
            width: 90%;
            padding: 0 2.5rem;
        }

        @include desktop {
            width: 60%;
            padding: 0 12.5rem;
        }
    }

    &__heading {
        @include section-heading;

        @include tablet {
            @include page-heading;
            margin-bottom: 0;
        }
    }

    &__details {
        display: flex;
        margin-bottom: 1rem;

        &--left {
            flex: 1;

            @include tablet {
                display: flex;
            }
        }

        &--right {
            flex: 1;

            @include tablet {
                display: flex;
                justify-content: flex-end;
            }

            @include desktop {
                padding-right: 1rem;
            }
        }
    }

    &__data {
        @include body-text;
        display: inline-block;
        color: $silver;
        margin-bottom: 0;

        @include desktop {
            font-size: 1rem;
        }

        &--channel {
            @include subheading;
            margin-bottom: 0;

            @include tablet {
                margin-right: 2.5rem;
            }
        }
    }

    &__icons {
        margin: 0 1rem;
        height: 1.3rem;

        @include desktop {
            height: 1.1rem;
        }
    }

    &__description {
        @include body-text;

        @include desktop {
            padding-right: 1rem;
        }

    }
}

.divider {
    border-top: 1px solid $mercury;

    &--hero-top {
        @extend .divider;

        @include tablet {
            display: none;
        }
    }
}