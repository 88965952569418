@use '../../styles/partials/colors' as *;
@use '../../styles/partials/media' as *;
@use '../../styles/partials/fonts' as *;

.comments {
    margin: 0 1rem;
    display: flex;
    justify-content: space-between;

    @include tablet {
        margin: 0 2rem;
    }

    @include desktop {
        margin: 0 0 0 6rem;
        padding-right: 1rem;
    }

    &__data {
        @include subheading;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--name {
            @include tablet {
                margin: 0;
            }
        }

        &--time {
            @include body-text;
            color: $silver;
        }
    }

    &__text {
        @include body-text;
        margin: 0;
    }

    &__avatar {
        vertical-align: middle;
        border-radius: 50%;
        height: 2.25rem;
        width: 2.25rem;
        margin: 1rem 0.5rem 1rem 0;
        background-color: $mercury;

        @include tablet {
            height: 3.25rem;
            width: 3.25rem;
        }

        @include desktop {
            margin-right: 1.5rem;
        }
    }

    &__details {
        width: 90%;
    }

    &__divider {
        border-top: 1px solid $mercury;
        margin: 1rem 1rem 0 1rem;

        @include tablet {
            margin: 1rem 2rem 0 2rem;
        }

        @include desktop {
            margin: 1rem 0 0 6rem;
        }

        &--hero-top {
            @extend .comments__divider;

            @include tablet {
                display: none;
            }
        }
    }
}