// === PRIMARY COLORS ===

$primary-black: #323232;
$primary-blue: #0095FF;
$silver: #AFAFAF;
$white: #FFFFFF;

// === SECONDARY COLORS ===

$secondary-black: #000000;
$secondary-blue: #0065AD;
$mercury: #E1E1E1;
$alabaster: #FAFAFA;